@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;400&display=swap');
html {
  margin: 0;
  padding: 0;
}
html body {
  background-color: #eaebf0;
}
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaebf0;
  /* background-color: #bbd7eb; */
}
div * {
  font-family: 'Noto Sans KR', sans-serif !important;
}

a {
  text-decoration: none;
}
.red-placeholder input::-webkit-input-placeholder {
  color: red !important;
  font-size: 12px !important;
}

.red-placeholder input::-moz-placeholder {
  color: red !important;
  font-size: 12px !important;
}

.red-placeholder input:-ms-input-placeholder {
  color: red !important;
  font-size: 12px !important;
}

.red-placeholder input::placeholder {
  color: red !important;
  font-size: 12px !important;
  font-weight: 600;
}
